import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const About = () => (
  <Layout>
    <div className="container1">
      <SEO title="About Me" />
      <img
        src={require("../images/headshot.png")}
        alt="headshot"
        style={{
          paddingLeft: 220,
        }}
      />
      <h3>
        my name is kevin h. phung and i am a full stack software engineer. i
        have a background in healthcare and love all things javascript. feel
        free to reach out if you have any questions about my applications or
        just want to chat!
      </h3>
      <a
        href="https://www.linkedin.com/in/kevin-h-phung-457102118/"
        target="_blank"
      >
        <img
          src={require("../images/linkedin.svg")}
          alt="logo"
          height="50"
          width="50"
        />
      </a>
      <a href="https://github.com/kphung29" target="_blank">
        <img
          src={require("../images/github.svg")}
          alt="logo"
          height="50"
          width="50"
        />
      </a>
      <a href="https://twitter.com/kevinphung_84" target="_blank">
        <img
          src={require("../images/twitter.svg")}
          alt="logo"
          height="50"
          width="50"
        />
      </a>
      <a href="https://medium.com/@kphung29" target="_blank">
        <img
          src={require("../images/medium.svg")}
          alt="logo"
          height="50"
          width="50"
        />
      </a>
    </div>
  </Layout>
);

export default About;
